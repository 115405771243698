<template>
	<form type="POST" v-on:submit.prevent="updateProductos" ref="productosForm">
		<h3 class="mb-3 text-primary">
			<font-awesome-icon :icon="faEdit" /> {{title}}
		</h3>
		<div class="card">
			<div class="card-body">
				<div class="row">
					<div class="col-lg-6 mb-3">
						<label for="nombre">Nombre del producto:</label>
						<input type="text" name="nombre" id="nombre" class="form-control" placeholder="Ingrese nombre" v-model="form.NOMBRE_PRODUCTO" maxlength="150" required :disabled="loading">
						<span v-if="errors.NOMBRE_PRODUCTO" class="text-danger">
							{{errors.NOMBRE_PRODUCTO[0]}}
						</span>
					</div>

					<div class="col-lg-6 mb-3">
						<label for="slug">SLUG:</label>
						<input type="text" name="slug" id="slug" class="form-control" placeholder="Personaliza la URL" v-model="form.SLUG" maxlength="150" @keydown.space.prevent required :disabled="loading">
						<span v-if="errors.SLUG" class="text-danger">
							{{errors.SLUG[0]}}
						</span>
					</div>

					<div class="col-lg-3 mb-3">
						<label for="proveedor">Proveedor:</label>
						<Proveedores type="text" name="proveedor" id="proveedor" v-model="form.CODIGO_PROVEEDOR" required :disabled="loading"/>
						<span v-if="errors.CODIGO_PROVEEDOR" class="text-danger">
							{{errors.CODIGO_PROVEEDOR[0]}}
						</span>
					</div>

					<div class="col-lg-3 mb-3">
						<label for="grupo">Grupo:</label>
						<ProductoGrupo type="text" name="grupo" id="grupo" v-model="form.CODIGO_PRODUCTO_GRUPO" required :disabled="loading"/>
						<span v-if="errors.CODIGO_PRODUCTO_GRUPO" class="text-danger">
							{{errors.CODIGO_PRODUCTO_GRUPO[0]}}
						</span>
					</div>

					<div class="col-lg-3 mb-3">
						<label for="tipo">Tipo:</label>
						<ProductoTipo type="text" name="tipo" id="tipo" v-model="form.CODIGO_PRODUCTO_TIPO" required :disabled="loading"/>
						<span v-if="errors.CODIGO_PRODUCTO_TIPO" class="text-danger">
							{{errors.CODIGO_PRODUCTO_TIPO[0]}}
						</span>
					</div>

					<div class="col-lg-3 mb-3">
						<label for="marca">Marca:</label>
						<ProductoMarca type="text" name="marca" id="marca" v-model="form.CODIGO_MARCA" required :disabled="loading"/>
						<span v-if="errors.CODIGO_MARCA" class="text-danger">
							{{errors.CODIGO_MARCA[0]}}
						</span>
					</div>

					<div class="col-lg-4 mb-3">
						<label for="precio">Precio:</label>
						<input type="text" name="precio" id="precio" class="form-control" placeholder="Ingrese precio" v-model="form.PRECIO" required :disabled="loading">
						<span v-if="errors.PRECIO" class="text-danger">
							{{errors.PRECIO[0]}}
						</span>
					</div>

					<div class="col-lg-4 mb-3">
						<label for="porcentaje">Porcentaje de comisión:</label>
						<input type="number" step="0.01" name="porcentaje" id="porcentaje" class="form-control" placeholder="Ingrese porcentaje" v-model="form.PORCENTAJE_COMISION" maxlength="150" required :disabled="loading">
						<span v-if="errors.PORCENTAJE_COMISION" class="text-danger">
							{{errors.PORCENTAJE_COMISION[0]}}
						</span>
					</div>

					<div class="col-lg-4 mb-3">
						<label for="codigo_comercial">Código comercial:</label>
						<input type="text" name="codigo_comercial" id="codigo_comercial" class="form-control" placeholder="Ingrese código" v-model="form.CODIGO_COMERCIAL" maxlength="150" :disabled="loading">
						<span v-if="errors.CODIGO_COMERCIAL" class="text-danger">
							{{errors.CODIGO_COMERCIAL[0]}}
						</span>
					</div>

					<div class="col-lg-12 mb-3">
						<label for="descripcion">Descripción:</label>
						<textarea name="descripcion" id="descripcion" class="form-control" placeholder="Ingrese descripción" v-model="form.DESCRIPCION" maxlength="750" required :disabled="loading"></textarea>
						<span v-if="errors.DESCRIPCION" class="text-danger">
							{{errors.DESCRIPCION[0]}}
						</span>
					</div>

					<div class="col-lg-12 mb-3">
						<UploadFile id="adjuntos" tipo="productos" :adjuntos="adjuntos" icon="ImageIcon" accept="image/*" class="h-100 w-100" limitFile="5" :codigo="codigo"></UploadFile>
					</div>
				</div>
			</div>
			<div class="card-footer">
				<div class="d-flex justify-content-end align-items-center">
					<div class="me-2">
						<router-link :to="{name: 'admin.index'}" class="btn btn-link">
							Cancelar
						</router-link>
					</div>
					<div>
						<button type="submit" class="btn btn-primary" :disabled="loading || !validateForm">
							<BtnLoading v-if="loadingProductos" />
                            <span v-else>
                                <font-awesome-icon :icon="faSave" /> Actualizar producto
                            </span>
						</button>
					</div>
				</div>
			</div>
		</div>
	</form>
</template>
<script type="text/javascript">
	import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
	import { faSave, faEdit } from '@fortawesome/free-solid-svg-icons'
	import BtnLoading from '@/components/BtnLoading.vue'
	import Proveedores from '@/components/catalogos/Proveedores.vue'
	import ProductoGrupo from '@/components/catalogos/ProductoGrupo.vue'
	import ProductoTipo from '@/components/catalogos/ProductoTipo.vue'
	import ProductoMarca from '@/components/catalogos/ProductoMarca.vue'
	import UploadFile from '@/components/UploadFile.vue'
	export default {
		components: {
			FontAwesomeIcon,
			BtnLoading,
			Proveedores,
			ProductoGrupo,
			ProductoTipo,
			ProductoMarca,
			UploadFile
		},
		data(){
			return {
				faSave,
				faEdit,
				form: {
					NOMBRE_PRODUCTO: '',
					SLUG: '',
					CODIGO_PROVEEDOR: '',
					CODIGO_PRODUCTO_GRUPO: '',
					CODIGO_PRODUCTO_TIPO: '',
					CODIGO_MARCA: '',
					PRECIO: '',
					PORCENTAJE_COMISION: '',
					CODIGO_COMERCIAL: '',
					DESCRIPCION: '',
				},
				adjuntos: [],

				loadingProductos: false,
				validateForm: false
			}
		},
		created(){
			this.loadData()
		},
		methods: {
			loadData(){
				this.$store.dispatch('showProductos', {
					id: this.codigo
				}).then(response => {
					if (response.data) {
						this.form.NOMBRE_PRODUCTO = response.data.NOMBRE_PRODUCTO
						this.form.SLUG = response.data.SLUG
						this.form.CODIGO_PROVEEDOR = response.data.CODIGO_PROVEEDOR
						this.form.CODIGO_PRODUCTO_GRUPO = response.data.CODIGO_PRODUCTO_GRUPO
						this.form.CODIGO_PRODUCTO_TIPO = response.data.CODIGO_PRODUCTO_TIPO
						this.form.CODIGO_MARCA = response.data.CODIGO_MARCA
						this.form.PRECIO = response.data.PRECIO
						this.form.PORCENTAJE_COMISION = response.data.PORCENTAJE_COMISION
						this.form.CODIGO_COMERCIAL = response.data.CODIGO_COMERCIAL
						this.form.DESCRIPCION = response.data.DESCRIPCION
						this.adjuntos = response.data.adjuntos || []
					}
					return response
				}).catch(error =>{
					this.$toasted.show('Error al leer registro')
					this.$router.push({name: 'admin.index'})
					return error
				})
			},
			updateProductos(){
				this.checkValidity()
				if (!this.validateForm) {
					return
				}
				this.loadingProductos = true
				this.form.id = this.codigo
				this.$store.dispatch('updateProductos', this.form).then((res) => {
					this.$toast.info(`El producto ha sido actualizado`)
					this.$router.push({name: 'admin.index'})
					return res
				}).catch(err => {
					if(err.response){
						if(typeof err.response.data === 'string'){
							this.$toast.error(err.response.data)
						}else{
							this.$toast.error('Error al crear registro')
						}
					}
				}).then(() => {
					this.loadingProductos = false
				})
			},
			checkValidity(){
				if (!this.$refs.productosForm){
					this.validateForm = false
				}else{
					this.validateForm = this.$refs.productosForm.checkValidity()
				}
			},
		},

		computed:{
			codigo(){
				return this.$route.params.id || -1
			},
			title(){
				return this.$route.meta.title ? this.$route.meta.title : ''
			},

			loading() {
				return this.$store.getters.loading
			},

			errors() {
				return this.$store.getters.errors || {}
			}
		},
		watch: {
			'form':{
				handler(val){
					this.checkValidity()
					return val
				},
				deep: true
			}
		}
	}
</script>