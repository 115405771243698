<template>
    <div>
        <div class="position-relative">
            <div class="position-absolute" style="left: 5px; top: 9px;">
                <button type="button" class="btn btn-outline-light btn-sm border-0 text-muted shadow-0 d-flex align-items-center" title="Actualizar" v-on:click="getData()" tabindex="-1" :disabled="loadingProductosMarcas">
                    <font-awesome-icon :icon="faSyncAlt" />
                </button>
            </div>
            <select class="form-select" :value="modelValue" @change="$emit('update:modelValue', $event.target.value)" style="padding-left: 42px;" :disabled="loadingProductosMarcas || !hiddeElement || disabledDF" :required="requiredDF" :id="idDF">
                <option value="" :disabled="loadingProductosMarcas" selected>
                    <span v-if="loadingProductosMarcas">Cargando...</span>
                    <span v-else>Seleccionar tipo</span>
                </option>
                <option :value="productoMarca.CODIGO_MARCA" v-text="productoMarca.DESCRIPCION_MARCA" v-for="(productoMarca, index) in productosMarcas" :key="index"></option>
            </select>
        </div>
        <slot></slot>
    </div>
</template>
<script>
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faSyncAlt } from '@fortawesome/free-solid-svg-icons'
export default {
    name: 'catalogo-productos-marcas',
    props: ['modelValue', 'disabled', 'required', 'id'],
    emits: ['update:modelValue'],
    components: {
        FontAwesomeIcon
    },
    data(){
        return {
            faSyncAlt,
            loadingProductosMarcas: false,
            hiddeElement: true
        }
    },
    mounted(){
        if(this.productosMarcas.length<=0){
            this.getData()
        }else{
            if(this.productosMarcas.length==1){
                this.hiddeElement = false
                this.$emit('update:modelValue', this.productosMarcas[0].CODIGO_MARCA)
            }else{
                this.hiddeElement = true
            }
        }
    },
    methods: {
        getData(){
            this.loadingProductosMarcas = true
            this.$store.dispatch('getProductosMarcas', {
                pagination: 0
            }).then(res => {
                if(res.data.length==1){
                    this.hiddeElement = false
                    this.$emit('update:modelValue', res.data[0].CODIGO_MARCA)
                }else{
                    this.hiddeElement = true
                }
                return res
            }).catch(err => {
                return err
            }).then(() => {
                this.loadingProductosMarcas = false
            })
        }
    },
    computed: {
        productosMarcas() {
            var obj = this.$store.getters.productosMarcas
            if (obj && Object.keys(obj).length === 0 && obj.constructor === Object) {
                return []
            }else{
                return obj || []
            }
        },
        disabledDF(){
            return this.disabled
        },
        requiredDF(){
            return this.required
        },
        idDF(){
            return this.id
        },
        loading() {
            return this.$store.getters.loading
        }
    }
}
</script>